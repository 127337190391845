.patient-details-screen__content-container {
    width: 60%;
}

.patient-details-screen__patient-age-body {
    margin-left: 30px;
    margin-right: 20px;
    height: fit-content;
}

.patient-details-screen__patient-details-container {
    margin-top: 40px;
    width: 50%;
    height: fit-content;
}

.patient-details-screen__patient-details-item {
    border-bottom: 1px solid var(--gray600);
    padding-top: 20px;
    padding-bottom: 20px;
}

.patient-details-screen__patient-details-body {
    margin-left: 5px;
}

.patient-details-screen__treatment-button {
    background-color: var(--gray300) !important;
    margin-top: 30px !important;
    width: fit-content;
}

.patient-details-screen__screen-container {
    width: 100%;
}

.patient-details-screen__treatment-information-container {
    margin-top: 60px;
    width: 35%;
}

.patient-details-screen__treatment-information {
    margin-top: 30px;
    text-align: center;
}

.patient-details-screen__treatment-completed-icon {
    background-color: var(--blue-gray);
    color: var(--white);
    margin-right: 12px !important;
}
