.device-help-screen__alert-body-fields {
    margin-top: 10px;
}

.device-help-screen__result-container {
    margin-top: 50px;
}

.device-help-screen__result-text--error {
    color: var(--red) !important;
    margin-top: 30px;
}

.device-help-screen__form-container {
    height: fit-content;
    min-height: fit-content;
    width: 60%;
}

.device-help-screen__header {
    margin-bottom: 30px !important;
}

.device-help-screen__form-button {
    background-color: var(--gray300) !important;
    margin-top: 30px !important;
    width: 80%;
}

.device-help-screen__icon-button {
    border: 0;
    margin-left: 10px;
    height: 50%;
    background-color: var(--white);
}

.device-help-screen__input-fields {
    width: 100%;
}

.device-help-screen__screen-container {
    width: 100%;
}

.device-help-screen__code-input {
    width: 60%;
}

