.home-screen__add-patient-box {
    min-height: 150px;
    width: 60%;
    background-color: var(--white);
    cursor: pointer;
    border: 2px dashed var(--gray800);
    margin-bottom: 35px;
    text-align: center;
}

.home-screen__all-patients-label {
    margin-bottom: 10px;
}

.home-screen__circle-label {
    color: var(--white) !important;
    background-color: var(--blue-gray) !important;
    margin-right: 12px !important;
}

.home-screen__flag-icon {
    margin-right: 0 !important;
}

.home-screen__hourglass-icon {
    margin-right: 0 !important;
}

.home-screen__notification-check-icon {
    color: var(--gray400);
    margin-right: 10px !important;
}

.home-screen__notification-container {
    height: 150px;
    width: 800px !important;
    margin-left: -400px !important;
}

.home-screen__notification-toast {
    height: 100%;
    background-color: var(--gray900) !important;
    border: 1px solid var(--gray800);
}

.home-screen__patient-sola-expiration-column {
    min-width: 35%;
    width: 55%;
}

.home-screen__patient-list-container {
    width: 60%;
}

.home-screen__patient-list-scroll {
    overflow-x: auto;
}

.home-screen__patient-list-item {
    width: calc(100% - 8px);
    min-width: 540px;
    min-height: 150px;
    margin: 4px 4px 10px;
    padding: 3%;
    border: 1px solid var(--gray600);
    background-color: var(--white);
    text-align: left;
    cursor: pointer;
}

.home-screen__patient-search-container {
    width: 60%;
    margin-bottom: 25px;
    min-height: 90px;
}

.home-screen__patient-treatments-column {
    min-width: 30%;
}

.home-screen__patient-upi-column {
    width: 35%;
    word-break: break-all;
}

.home-screen__screen-container {
    width: 100%;
    flex-grow: 1;
}

.home-screen__treatment-expiration-label {
    margin-bottom: 10px;
}
