.payment-details-screen__payment-link {
    align-self: center;
    margin-top: 5% !important;
}

.payment-details-screen__form-box {
    background-color: var(--white);
    border: 2px solid var(--gray800);
    padding: 10% 10% 3%;
}

.payment-details-screen__form-container {
    height: fit-content;
    min-height: fit-content;
    min-width: 450px;
    width: 45%;
}

.payment-details-screen__form-header {
    margin-bottom: 30px;
}

.payment-details-screen__form-line-item {
    margin-bottom: 20px;
}

.payment-details-screen__patient-consent-container {
    align-self: center;
    margin-top: 5%;
    width: 80%;
}

.payment-details-screen__patient-consent-checkbox {
    transform: scale(1.5);
}

.payment-details-screen__patient-consent-item {
    margin-left: 20px !important;
    margin-top: 0 !important;
}

.payment-details-screen__patient-consent-string {
    margin-left: 10px;
}

.payment-details-screen__patient-consent-string--color-normal {
    color: var(--gray300) !important;
}

.payment-details-screen__patient-consent-string--color-error {
    color: var(--red) !important;
}

.payment-details-screen__payment-button {
    background-color: var(--gray300) !important;
    align-self: flex-end;
    margin-bottom: 20px !important;
}

.payment-details-screen__price-to-pay-container {
    width: 100%;
}

.payment-details-screen__refund-notice {
    margin: 10px 0;
}

.payment-details-screen__secure-checkout {
    align-self: center;
}

.payment-details-screen__screen-container {
    min-width: fit-content;
    width: 100%;
}

/* Remove the spinner from number input field (up and down arrows) */
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
