.login-screen__button-field {
    background-color: var(--gray300) !important;
    margin-bottom: 30px !important;
    width: 100%;
}

.login-screen__form-container {
    height: fit-content;
    width: 20%;
}

.login-screen__form-fields {
    margin-bottom: 15px !important;
    width: 100%;
}

.login-screen__label {
    margin-bottom: 10px !important;
}

.login-screen__link-text {
    margin-bottom: 15px;
}

.login-screen__logo {
    width: 200px;
}

.login-screen__screen-container {
    height: 100%;
    width: 100%;
}

.login-screen__version {
    text-align: center;
    margin: 20px;
}
