.register-provider-screen__back-button-container {
    width: 65%;
}

.register-provider-screen__form-button {
    background-color: var(--gray300) !important;
}

.register-provider-screen__form-container {
    width: 60%;
}

.register-provider-screen__form-group {
    margin-bottom: 30px;
}

.register-provider-screen__form-header {
    margin-bottom: 20px !important;
}

.register-provider-screen__input-field {
    width: 100%;
}

.register-provider-screen__password-hint {
    margin: 10px 0;
}

.register-provider-screen__input-row {
    margin-bottom: 10px;
}

.register-provider-screen__label {
    margin-bottom: 10px;
}

.register-provider-screen__half-width-input {
    width: 49%;
}

.register-provider-screen__screen-container {
    padding-top: 5%;
    padding-bottom: 5%;
}
