.password-reset-screen__container {
    width: 100%;
    height: 100%;
}

.password-reset-screen__form-container {
    height: fit-content;
    width: 350px;
}

.password-reset-screen__button-field {
    background-color: var(--gray300) !important;
    margin-bottom: 30px !important;
    margin-top: 15px !important;
    width: 100%;
}

.password-reset-screen__form-fields {
    width: 100%;
}

.password-reset-screen__password-requirements {
    color: var(--red) !important;
}

.password-reset-screen__label {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}
