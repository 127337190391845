.add-patient-screen__consent-checkbox {
    transform: scale(1.5);
    margin-left: 5px;
}

.add-patient-screen__consent-item {
    margin-left: 20px !important;
    margin-top: 0 !important;
}

.add-patient-screen__consent-string {
    color: var(--gray300) !important;
}

.add-patient-screen__consent-string--error {
    color: var(--red) !important;
}

.add-patient-screen__form-button {
    background-color: var(--gray300) !important;
}

.add-patient-screen__form-container {
    height: fit-content;
    min-height: fit-content;
    width: 40%;
}

.add-patient-screen__form-group {
    margin-bottom: 30px;
}

.add-patient-screen__form-header {
    margin-bottom: 20px !important;
}

.add-patient-screen__input-fields {
    width: 100%;
}

.add-patient-screen__label {
    margin-bottom: 10px !important;
}

.add-patient-screen__large-input-container {
    width: 49%;
}

.add-patient-screen__screen-container {
    width: 100%;
}

