.page-not-found__back-button-container {
    margin-top: 30px !important;
    width: 65%;
}

.page-not-found-screen__screen-container {
    width: 100%;
}

.page-not-found-screen__error-message-container {
    margin-top: 60px;
}

.hcp-base-screen__back-button-container {
    margin-bottom: 30px;
    width: 65%;
}

.hcp-base-screen__footer {
    border-top: 1px solid var(--gray600);
    min-height: 75px;
    min-width: 540px;
    width: 75%;
    flex-direction: column;
}

.hcp-base-screen__footer-contact-info-icon {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.hcp-base-screen__footer-copyright {
    color: var(--gray600) !important;
}

.hcp-base-screen__logo {
    width: 70px;
    cursor: pointer;
}

.hcp-base-screen__base-screen-container {
    height: 100%;
    width: 100%;
}

.hcp-base-screen__screen-container {
    height: 100%;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
}

.hcp-base-screen__title-bar {
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.4);
    min-height: 75px;
    width: 100%;
    z-index: 1;
}

.hcp-base-screen__title-bar-contents {
    flex-grow: 1;
    width: 75%;
}

.hcp-base-screen__username-button {
    border: 0;
    margin-right: 10px;
    background-color: var(--white);
}

.home-screen__add-patient-box {
    min-height: 150px;
    width: 60%;
    background-color: var(--white);
    cursor: pointer;
    border: 2px dashed var(--gray800);
    margin-bottom: 35px;
    text-align: center;
}

.home-screen__all-patients-label {
    margin-bottom: 10px;
}

.home-screen__circle-label {
    color: var(--white) !important;
    background-color: var(--blue-gray) !important;
    margin-right: 12px !important;
}

.home-screen__flag-icon {
    margin-right: 0 !important;
}

.home-screen__hourglass-icon {
    margin-right: 0 !important;
}

.home-screen__notification-check-icon {
    color: var(--gray400);
    margin-right: 10px !important;
}

.home-screen__notification-container {
    height: 150px;
    width: 800px !important;
    margin-left: -400px !important;
}

.home-screen__notification-toast {
    height: 100%;
    background-color: var(--gray900) !important;
    border: 1px solid var(--gray800);
}

.home-screen__patient-sola-expiration-column {
    min-width: 35%;
    width: 55%;
}

.home-screen__patient-list-container {
    width: 60%;
}

.home-screen__patient-list-scroll {
    overflow-x: auto;
}

.home-screen__patient-list-item {
    width: calc(100% - 8px);
    min-width: 540px;
    min-height: 150px;
    margin: 4px 4px 10px;
    padding: 3%;
    border: 1px solid var(--gray600);
    background-color: var(--white);
    text-align: left;
    cursor: pointer;
}

.home-screen__patient-search-container {
    width: 60%;
    margin-bottom: 25px;
    min-height: 90px;
}

.home-screen__patient-treatments-column {
    min-width: 30%;
}

.home-screen__patient-upi-column {
    width: 35%;
    word-break: break-all;
}

.home-screen__screen-container {
    width: 100%;
    flex-grow: 1;
}

.home-screen__treatment-expiration-label {
    margin-bottom: 10px;
}

.add-patient-screen__consent-checkbox {
    transform: scale(1.5);
    margin-left: 5px;
}

.add-patient-screen__consent-item {
    margin-left: 20px !important;
    margin-top: 0 !important;
}

.add-patient-screen__consent-string {
    color: var(--gray300) !important;
}

.add-patient-screen__consent-string--error {
    color: var(--red) !important;
}

.add-patient-screen__form-button {
    background-color: var(--gray300) !important;
}

.add-patient-screen__form-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 40%;
}

.add-patient-screen__form-group {
    margin-bottom: 30px;
}

.add-patient-screen__form-header {
    margin-bottom: 20px !important;
}

.add-patient-screen__input-fields {
    width: 100%;
}

.add-patient-screen__label {
    margin-bottom: 10px !important;
}

.add-patient-screen__large-input-container {
    width: 49%;
}

.add-patient-screen__screen-container {
    width: 100%;
}


.patient-details-screen__content-container {
    width: 60%;
}

.patient-details-screen__patient-age-body {
    margin-left: 30px;
    margin-right: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.patient-details-screen__patient-details-container {
    margin-top: 40px;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.patient-details-screen__patient-details-item {
    border-bottom: 1px solid var(--gray600);
    padding-top: 20px;
    padding-bottom: 20px;
}

.patient-details-screen__patient-details-body {
    margin-left: 5px;
}

.patient-details-screen__treatment-button {
    background-color: var(--gray300) !important;
    margin-top: 30px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.patient-details-screen__screen-container {
    width: 100%;
}

.patient-details-screen__treatment-information-container {
    margin-top: 60px;
    width: 35%;
}

.patient-details-screen__treatment-information {
    margin-top: 30px;
    text-align: center;
}

.patient-details-screen__treatment-completed-icon {
    background-color: var(--blue-gray);
    color: var(--white);
    margin-right: 12px !important;
}

.payment-details-screen__payment-link {
    align-self: center;
    margin-top: 5% !important;
}

.payment-details-screen__form-box {
    background-color: var(--white);
    border: 2px solid var(--gray800);
    padding: 10% 10% 3%;
}

.payment-details-screen__form-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    min-width: 450px;
    width: 45%;
}

.payment-details-screen__form-header {
    margin-bottom: 30px;
}

.payment-details-screen__form-line-item {
    margin-bottom: 20px;
}

.payment-details-screen__patient-consent-container {
    align-self: center;
    margin-top: 5%;
    width: 80%;
}

.payment-details-screen__patient-consent-checkbox {
    transform: scale(1.5);
}

.payment-details-screen__patient-consent-item {
    margin-left: 20px !important;
    margin-top: 0 !important;
}

.payment-details-screen__patient-consent-string {
    margin-left: 10px;
}

.payment-details-screen__patient-consent-string--color-normal {
    color: var(--gray300) !important;
}

.payment-details-screen__patient-consent-string--color-error {
    color: var(--red) !important;
}

.payment-details-screen__payment-button {
    background-color: var(--gray300) !important;
    align-self: flex-end;
    margin-bottom: 20px !important;
}

.payment-details-screen__price-to-pay-container {
    width: 100%;
}

.payment-details-screen__refund-notice {
    margin: 10px 0;
}

.payment-details-screen__secure-checkout {
    align-self: center;
}

.payment-details-screen__screen-container {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 100%;
}

/* Remove the spinner from number input field (up and down arrows) */
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.payment-success-screen__button {
    background-color: var(--gray300) !important;
}

.payment-success-screen__body-item {
    margin-bottom: 20px;
}

.payment-success-screen__container {
    min-width: 400px;
    width: 50%;
    flex-grow: 1;
}

.payment-success-screen__icon {
    margin-right: 20px !important;
}

.payment-failure-screen__button {
    background-color: var(--gray300) !important;
}

.payment-failure-screen__body-item {
    margin-bottom: 20px;
}

.payment-failure-screen__container {
    min-width: 400px;
    width: 50%;
    flex-grow: 1;
}

.device-help-screen__alert-body-fields {
    margin-top: 10px;
}

.device-help-screen__result-container {
    margin-top: 50px;
}

.device-help-screen__result-text--error {
    color: var(--red) !important;
    margin-top: 30px;
}

.device-help-screen__form-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 60%;
}

.device-help-screen__header {
    margin-bottom: 30px !important;
}

.device-help-screen__form-button {
    background-color: var(--gray300) !important;
    margin-top: 30px !important;
    width: 80%;
}

.device-help-screen__icon-button {
    border: 0;
    margin-left: 10px;
    height: 50%;
    background-color: var(--white);
}

.device-help-screen__input-fields {
    width: 100%;
}

.device-help-screen__screen-container {
    width: 100%;
}

.device-help-screen__code-input {
    width: 60%;
}


.register-provider-screen__back-button-container {
    width: 65%;
}

.register-provider-screen__form-button {
    background-color: var(--gray300) !important;
}

.register-provider-screen__form-container {
    width: 60%;
}

.register-provider-screen__form-group {
    margin-bottom: 30px;
}

.register-provider-screen__form-header {
    margin-bottom: 20px !important;
}

.register-provider-screen__input-field {
    width: 100%;
}

.register-provider-screen__password-hint {
    margin: 10px 0;
}

.register-provider-screen__input-row {
    margin-bottom: 10px;
}

.register-provider-screen__label {
    margin-bottom: 10px;
}

.register-provider-screen__half-width-input {
    width: 49%;
}

.register-provider-screen__screen-container {
    padding-top: 5%;
    padding-bottom: 5%;
}

.forgot-password-modal__button {
    background-color: var(--gray300) !important;
    width: 100%;
}

.forgot-password-modal__button-container {
    width: 70%;
}

.forgot-password-modal__description {
    margin-bottom: 40px !important;
    width: 70%;
    text-align: center;
}

.forgot-password-modal__header {
    margin-bottom: 40px !important;
    margin-top: 20px !important;
}

.forgot-password-modal__input-container {
    width: 70%;
    margin-bottom: 20px;
}

.forgot-password-modal__input-label {
    margin-bottom: 10px !important;
}

.forgot-password-modal__item {
    margin-top: 40px !important;
    padding-bottom: 30px;
}


.login-screen__button-field {
    background-color: var(--gray300) !important;
    margin-bottom: 30px !important;
    width: 100%;
}

.login-screen__form-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 20%;
}

.login-screen__form-fields {
    margin-bottom: 15px !important;
    width: 100%;
}

.login-screen__label {
    margin-bottom: 10px !important;
}

.login-screen__link-text {
    margin-bottom: 15px;
}

.login-screen__logo {
    width: 200px;
}

.login-screen__screen-container {
    height: 100%;
    width: 100%;
}

.login-screen__version {
    text-align: center;
    margin: 20px;
}

.password-reset-screen__container {
    width: 100%;
    height: 100%;
}

.password-reset-screen__form-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 350px;
}

.password-reset-screen__button-field {
    background-color: var(--gray300) !important;
    margin-bottom: 30px !important;
    margin-top: 15px !important;
    width: 100%;
}

.password-reset-screen__form-fields {
    width: 100%;
}

.password-reset-screen__password-requirements {
    color: var(--red) !important;
}

.password-reset-screen__label {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --white: #FFFFFF;
    --gray900: #E7E7E7;
    --gray800: #CACACA;
    --gray600: #979797;
    --gray400: #686868;
    --gray300: #474A4F;
    --blue-gray: #8D99B6;
    --red: #F44336;
}

