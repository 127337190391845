.page-not-found__back-button-container {
    margin-top: 30px !important;
    width: 65%;
}

.page-not-found-screen__screen-container {
    width: 100%;
}

.page-not-found-screen__error-message-container {
    margin-top: 60px;
}
