.forgot-password-modal__button {
    background-color: var(--gray300) !important;
    width: 100%;
}

.forgot-password-modal__button-container {
    width: 70%;
}

.forgot-password-modal__description {
    margin-bottom: 40px !important;
    width: 70%;
    text-align: center;
}

.forgot-password-modal__header {
    margin-bottom: 40px !important;
    margin-top: 20px !important;
}

.forgot-password-modal__input-container {
    width: 70%;
    margin-bottom: 20px;
}

.forgot-password-modal__input-label {
    margin-bottom: 10px !important;
}

.forgot-password-modal__item {
    margin-top: 40px !important;
    padding-bottom: 30px;
}

