.payment-failure-screen__button {
    background-color: var(--gray300) !important;
}

.payment-failure-screen__body-item {
    margin-bottom: 20px;
}

.payment-failure-screen__container {
    min-width: 400px;
    width: 50%;
    flex-grow: 1;
}
