:root {
    --white: #FFFFFF;
    --gray900: #E7E7E7;
    --gray800: #CACACA;
    --gray600: #979797;
    --gray400: #686868;
    --gray300: #474A4F;
    --blue-gray: #8D99B6;
    --red: #F44336;
}
