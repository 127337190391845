.hcp-base-screen__back-button-container {
    margin-bottom: 30px;
    width: 65%;
}

.hcp-base-screen__footer {
    border-top: 1px solid var(--gray600);
    min-height: 75px;
    min-width: 540px;
    width: 75%;
    flex-direction: column;
}

.hcp-base-screen__footer-contact-info-icon {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.hcp-base-screen__footer-copyright {
    color: var(--gray600) !important;
}

.hcp-base-screen__logo {
    width: 70px;
    cursor: pointer;
}

.hcp-base-screen__base-screen-container {
    height: 100%;
    width: 100%;
}

.hcp-base-screen__screen-container {
    height: 100%;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
}

.hcp-base-screen__title-bar {
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.4);
    min-height: 75px;
    width: 100%;
    z-index: 1;
}

.hcp-base-screen__title-bar-contents {
    flex-grow: 1;
    width: 75%;
}

.hcp-base-screen__username-button {
    border: 0;
    margin-right: 10px;
    background-color: var(--white);
}
